import { useCallback, useEffect, useRef, useState } from 'react'

export default function useThrottle(fn, delay) {
  const [ready, setReady] = useState(true)
  const timerRef = useRef(undefined)

  const throttledFunction = useCallback(
    (...args) => {
      if (!ready) {
        return
      }

      setReady(false)
      fn(...args)
    },
    [ready, fn]
  )

  useEffect(() => {
    if (!ready) {
      timerRef.current = window.setTimeout(() => {
        setReady(true)
      }, delay)

      return () => window.clearTimeout(timerRef.current)
    }
  }, [ready, delay])

  return throttledFunction
}
