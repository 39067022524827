import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Cancel, CheckCircle, Pending, PhonelinkOff } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

import { useDevice } from '../../../contexts/device'
import { requestApi } from '../../../helpers'
import { NoData } from '../../../components/NoData'

const PendingDevices = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { pendingDevices, addPendingDevice, removePendingDevice } = useDevice()
  const history = useHistory()

  const approveDevice = async (deviceId, device) => {
    try {
      await requestApi.post('/device/approve', {
        id: deviceId,
        deviceConfig: device,
      })
      addPendingDevice(deviceId)
      history.go(0)
    } catch (err) {
      console.error(err)
    }
  }

  const denyDevice = async (deviceId) => {
    try {
      await requestApi.post('/device/deny', { id: deviceId })
      removePendingDevice(deviceId)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Card sx={isMobile ? {} : { height: '87.5vh', width: '37.5vw' }}>
      <CardHeader
        title={
          <Typography fontSize={16} fontWeight="bold">
            Pendentes
          </Typography>
        }
      />
      <CardContent>
        {pendingDevices ? (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {Object.entries(pendingDevices).map(([deviceId, device]) => (
              <ListItem
                key={deviceId}
                secondaryAction={
                  <>
                    <Tooltip title="Aprovar">
                      <IconButton
                        color="success"
                        onClick={() => approveDevice(deviceId, device)}
                      >
                        <CheckCircle />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Rejeitar"
                      sx={{ ml: 2 }}
                      onClick={() => denyDevice(deviceId)}
                    >
                      <IconButton color="error">
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                disablePadding
              >
                <ListItemButton
                  onClick={() => history.push(`/devices/pending/${deviceId}`)}
                  sx={{ mb: 2 }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Pending />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={device.name}
                    secondary={device.description}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <NoData Icon={PhonelinkOff} subject="dispositivo pendente" />
        )}
      </CardContent>
    </Card>
  )
}

export default PendingDevices
