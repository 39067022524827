import { Link, Stack, Box } from '@mui/material'

export const NoData = ({ Icon, subject, subjectGender = 'M', subtitle }) => {
  const isFemaleSubject = subjectGender === 'F'

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ color: 'gray', my: 5 }}
      spacing={-0.1}
    >
      <Icon />
      <Box py={1} />
      <p style={{ fontWeight: 'bold' }}>
        Nenhum{isFemaleSubject ? 'a' : ''} {subject} encontrad
        {isFemaleSubject ? 'a' : 'o'}.
      </p>

      {subtitle ? (
        <p>{subtitle}</p>
      ) : (
        <p>
          Crie um{isFemaleSubject ? 'a' : ''} seguindo as instruções para
          dispositivos{' '}
          <Link
            href="https://gitlab.com/saiot/saiot-2.0/devices/device-lib"
            underline="none"
          >
            reais
          </Link>{' '}
          ou{' '}
          <Link
            href="https://gitlab.com/saiot/saiot-2.0/simulated-device-2"
            underline="none"
          >
            virtuais
          </Link>
          .
        </p>
      )}
    </Stack>
  )
}
