import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Utils as QbUtils } from '@react-awesome-query-builder/mui'

import withSnackbar from '../../components/Snackbar'
import { requestApi } from '../../helpers'
import { getConfig } from './NewRule/config'
import RuleCard from './RuleCard'
import { NoData } from '../../components/NoData'
import { BallotOutlined } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
  gridItem: {
    display: 'flex',
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: '0.5em',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75em',
  },
  cardTitleText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  cardSubTitleText: {
    color: 'black',
    marginRight: '0.5em',
  },
  cardIconsArea: {
    alignSelf: 'stretch',
  },
}))

const Rules = ({ snackbarShowMessage }) => {
  const classes = useStyles()
  const [devices, setDevices] = useState([])
  const [rules, setRules] = useState([])

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const { data } = await requestApi.get('/device')
        setDevices(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchDevices()
  }, [])

  useEffect(() => {
    const fetchRules = async () => {
      try {
        const deviceIds = devices.map((device) => device.id)
        if (!deviceIds.length) return

        const { data } = await requestApi.get('/rules', {
          params: {
            deviceId: deviceIds,
          },
        })
        setRules((rules) => [...rules, ...data])
      } catch (error) {
        console.error(error)
      }
    }
    fetchRules()
  }, [devices])

  const getDevice = (deviceId) =>
    devices.find((device) => device.id === deviceId)

  const deleteRule = async (ruleId) => {
    try {
      await requestApi.delete(`/rules/${ruleId}`)
      setRules((rules) => rules.filter((rule) => rule.id !== ruleId))
      snackbarShowMessage('Regra deletada com sucesso.', 'success')
    } catch (error) {
      snackbarShowMessage('Ocorreu um erro ao deletar a regra.', 'error')
    }
  }

  return (
    <>
      <div className={classes.pageHeader}>
        <p className={classes.pageTitle}>Regras</p>
        <Link to="/rules/new">
          <Button variant="contained" color="primary">
            Criar regra
          </Button>
        </Link>
      </div>
      {rules && rules.length > 0 ? (
        <Grid container spacing={1} alignItems="stretch">
          {rules.map((rule) => {
            const devices = rule.deviceIds.map(getDevice)

            devices.forEach((device) => {
              if (!device) {
                deleteRule(rule.id)
              }
            })

            const config = getConfig(devices)
            const tree = QbUtils.loadFromJsonLogic(
              JSON.parse(rule.expression),
              config
            )
            const expression = QbUtils.queryString(tree, config, true)

            return (
              <Grid
                item
                key={rule.id}
                sm={4}
                xs={12}
                className={classes.gridItem}
              >
                <RuleCard
                  ruleId={rule.id}
                  actions={rule.actions}
                  expression={expression}
                  getDevice={getDevice}
                  deleteRule={deleteRule}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <NoData
          Icon={BallotOutlined}
          subject="regra"
          subjectGender="F"
          subtitle={' '}
        />
      )}
    </>
  )
}

export default withSnackbar(Rules)
