import React, { useEffect, useState } from 'react'
import { Slider } from '@mui/material'

import useSmartSend from '../../../../hooks/useSmartSend'

const SliderActuator = ({ deviceId, id, min, max, step, value }) => {
  const [sliderValue, setSliderValue] = useState(value)
  const sendDeviceValue = useSmartSend(deviceId, id)

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const marks = [
    {
      value: min,
      label: min.toString(),
    },
    {
      value: max,
      label: max.toString(),
    },
  ]

  const changeValue = async (_, newValue) => {
    if (newValue === sliderValue) return

    setSliderValue(newValue)
    sendDeviceValue([newValue])
  }

  return (
    <Slider
      value={sliderValue}
      step={step}
      min={min}
      max={max}
      marks={marks}
      onChange={changeValue}
      valueLabelDisplay="auto"
    />
  )
}

export default SliderActuator
