import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import {
  TextField,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useHistory } from 'react-router-dom'

import Transition from '../../components/Transition'
import styles from './styles'
import { requestApi } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}))

const ChangePassword = (props) => {
  const classes = useStyles()

  const [password, setPassword] = useState(null)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogContent, setDialogContent] = useState('')
  const [error, setError] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (password === '') setPasswordError('Esse campo é obrigatorio')
    else setPasswordError('')
  }, [password])

  useEffect(() => {
    if (confirmPassword === '')
      setConfirmPasswordError('Esse campo é obrigatorio')
    else setConfirmPasswordError('')
  }, [confirmPassword])

  useEffect(() => {
    if (password !== confirmPassword)
      setConfirmPasswordError('As senhas não são iguais')
    else setConfirmPasswordError('')
  }, [password, confirmPassword])

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validate()) {
      try {
        await props.handleSubmit(password)
        setDialogTitle(
          `${props.OAuth ? 'Criação' : 'Atualização '} bem sucedida`
        )
        setDialogContent(
          `Sua senha foi ${props.OAuth ? 'criada' : 'atualizada'} com sucesso`
        )
        setDialogOpen(true)
      } catch ({ response }) {
        setDialogTitle('Erro')
        setDialogContent(response.data.message)
        setDialogOpen(true)
        setError(true)
      }
    }
  }

  const validate = () => {
    let bool = true

    if (!password) {
      setPasswordError('Esse campo é obrigatório')
      bool = false
    } else if (password.length < 8 || password.length > 32) {
      setPasswordError('A senha tem que ter entre 8 e 32 caracteres')
      bool = false
    }
    if (!confirmPassword) {
      setConfirmPasswordError('Esse campo é obrigatório')
      bool = false
    }

    if (passwordError.length || confirmPasswordError.length) bool = false
    return bool
  }

  const handleClose = () => {
    setDialogOpen(false)
    if (!error)
      setTimeout(async () => {
        const query = new URLSearchParams(window.location.search)

        const email = query.get('email')
        try {
          await requestApi.post('auth/user/login', { email, password })
          window.location.reload(false)
        } catch (error) {
          history.push('/login')
        }
      }, 250)
  }

  return (
    <Paper className={classes.authFormPaper} elevation={3} variant="outlined">
      <div className={classes.mainHolder}>
        <div className={classes.subHolder}>
          <LockIcon className={classes.authIcon} />
        </div>
        <div className={classes.subHolder}>
          <form className={classes.authForm} onSubmit={handleSubmit}>
            <TextField
              className={classes.authFormTextfield}
              required
              label="Senha"
              type={visiblePassword ? 'text' : 'password'}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    {visiblePassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              fullWidth
              value={password || ''}
              onChange={(event) => setPassword(event.target.value)}
              error={!!passwordError}
              helperText={passwordError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Confirmação da senha"
              type={visibleConfirmPassword ? 'text' : 'password'}
              value={confirmPassword || ''}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    onClick={() =>
                      setVisibleConfirmPassword(!visibleConfirmPassword)
                    }
                  >
                    {visibleConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(event) => setConfirmPassword(event.target.value)}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
            />
            <Button
              style={{ marginTop: 15 }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              {props.OAuth ? 'Criar ' : 'Atualizar '} senha
            </Button>
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default ChangePassword
