import React from 'react'
import { makeStyles } from '@mui/styles'

import logo from '../../assets/favicon-white.svg'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#006064',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SplashScreen = () => {
  const classes = useStyles()

  return (
    <div className={classes.background}>
      <img src={logo} alt="Logo do SaIoT - Icone de uma casa branca" />
    </div>
  )
}

export default SplashScreen
