import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useAuth } from '../contexts/auth'
import SplashScreen from '../components/SplashScreen'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'

const Router = () => {
  const { loading, logged } = useAuth()

  if (loading) {
    return <SplashScreen />
  }

  return (
    <BrowserRouter>
      {logged ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  )
}

export default Router
