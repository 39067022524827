import { createTheme } from '@mui/material'

const typography = {
  h5: {
    fontWeight: 500,
    fontSize: '16px',
  },
}

const button = {
  MuiButton: {
    styleOverrides: {
      root: {
        margin: 5,
      },
      containedSecondary: {
        backgroundColor: '#DB4437',
        '&:hover': {
          backgroundColor: '#AF362C',
        },
      },
    },
  },
}

export const lightTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#006060',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '0.5em 0',
        },
      },
    },
    ...button,
  },
  palette: {
    type: 'light',

    background: {
      main: '#F1F1F1',
    },

    primary: {
      main: '#008585',
    },
  },
  typography,
})

export const darkTheme = createTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#006060',
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: 'center',
      },
    },
    ...button,
  },
  palette: {
    type: 'dark',

    primary: {
      main: '#008585',
    },
  },
  typography,
})
