import {
  Box,
  Card,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Delete, MoreVert } from '@mui/icons-material'
import React, { useState } from 'react'

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.75em',
    width: '100%',
  },
  cardTitleText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  cardSubTitleText: {
    color: 'black',
    marginRight: '0.5em',
  },
  contentArea: {
    width: '100%',
  },
  cardIconsArea: {
    alignSelf: 'stretch',
  },
  actionsSection: {
    marginTop: '0.5em',
    marginBottom: '0.25em',
  },
  actionItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const actionTypeLabels = {
  act: 'Atuação',
}

const RuleCard = ({ ruleId, expression, actions, getDevice, deleteRule }) => {
  const classes = useStyles()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  return (
    <Card className={classes.card}>
      <div className={classes.contentArea}>
        <Typography className={classes.cardTitleText}>Expressão</Typography>
        <Typography color="textSecondary">{expression}</Typography>
        <Box m={1.5} />
        <Typography className={classes.cardTitleText}>Ações</Typography>
        {actions.map((action, actionIndex) => {
          const actuatorDeviceName = getDevice(action.deviceId).name

          return (
            <div key={actionIndex} className={classes.actionsSection}>
              <Chip label={actionTypeLabels[action.type]} size="small" />
              <div className={classes.actionItem}>
                <Typography className={classes.cardSubTitleText}>
                  Dispositivo
                </Typography>
                <Typography color="textSecondary">
                  {actuatorDeviceName}
                </Typography>
              </div>
              <div className={classes.actionItem}>
                <Typography className={classes.cardSubTitleText}>
                  Atuador
                </Typography>
                <Typography color="textSecondary">
                  {action.actuatorId}
                </Typography>
              </div>
              <div className={classes.actionItem}>
                <Typography className={classes.cardSubTitleText}>
                  Valor
                </Typography>
                <Typography color="textSecondary">
                  {action.actuatorValue}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.cardIconsArea}>
        <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={() => deleteRule(ruleId)}>
            <Delete />
            Deletar regra
          </MenuItem>
        </Menu>
      </div>
    </Card>
  )
}

export default RuleCard
