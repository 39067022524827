const styles = (theme) => ({
  mainHolder: {
    display: 'grid',
    gridTemplateRows: '60px',
    height: '100%',
    animation: `$fadein 0.5s ${theme.transitions.easing.easeOut}`,
  },
  subHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  authIcon: {
    height: '48px !important',
    width: '48px !important',
    color: '#006060',
  },
  authFormPaper: {
    padding: '30px 20px',
  },
  authForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    minWidth: '240px',
    maxWidth: '300px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
  },
  authFormTextfield: {
    marginBottom: '7px !important',
  },
  '@keyframes fadein': {
    from: {
      transform: 'translate3d(0px, 10px, 0px)',
      opacity: 0,
    },
    to: {
      transform: 'translate3d(0px, 0px, 0px)',
      opacity: 1,
    },
  },
})

export default styles
