import { Button, Card } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Utils as QbUtils } from '@react-awesome-query-builder/mui'
import '@react-awesome-query-builder/mui/css/styles.css'

import withSnackbar from '../../../components/Snackbar'
import { useDevice } from '../../../contexts/device'
import { useAuth } from '../../../contexts/auth'
import { requestApi } from '../../../helpers'
import { getConfig } from './config'
import RuleDevices from './RuleDevices'
import RuleActions from './RuleActions'
import Rule from './Rule'

const useStyles = makeStyles(() => ({
  card: {
    padding: '0.5em 1em',
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: '0.5em',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: '1em',
    marginBottom: '0.5em',
  },
  actionSection: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: '0.5em',
  },
  successiveSelect: {
    marginLeft: '2em',
  },
  button: {
    margin: '2em 0',
  },
}))

const queryValue = { id: QbUtils.uuid(), type: 'group' }

const NewRule = ({ snackbarShowMessage }) => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useAuth()
  const { devices } = useDevice()
  const [selectedDevices, setSelectedDevices] = useState([0])
  const [selectedActions, setSelectedActions] = useState([0])

  const config = getConfig(selectedDevices)

  const [ruleState, setRuleState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  })

  useEffect(() => {
    setRuleState(() => {
      const newConfig = getConfig(selectedDevices)
      return {
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), newConfig),
        config: newConfig,
      }
    })
  }, [selectedDevices])

  const createRule = async () => {
    try {
      await requestApi.post('/rules', {
        userId: user.id,
        deviceIds: selectedDevices.map((device) => device.id),
        expression: getJsonLogic(),
        actions: selectedActions,
      })
      snackbarShowMessage(`Regra criada com sucesso.`, 'success')
      setTimeout(() => history.push('/rules'), 1000)
    } catch (error) {
      snackbarShowMessage(
        `Ocorreu um erro ao criar a regra, por favor tente novamente mais tarde.`,
        'error'
      )
    }
  }

  const getJsonLogic = () => {
    return JSON.stringify(
      QbUtils.jsonLogicFormat(ruleState.tree, ruleState.config).logic
    )
  }

  return (
    <div>
      <p className={classes.pageTitle}>Nova regra</p>
      <Card className={classes.card}>
        <p className={classes.sectionTitle}>Dispositivos</p>
        <RuleDevices
          devices={devices}
          selectedDevices={selectedDevices}
          setSelectedDevices={setSelectedDevices}
        />

        <p className={classes.sectionTitle}>Regra</p>
        <Rule
          ruleState={ruleState}
          setRuleState={setRuleState}
        />

        <p className={classes.sectionTitle}>Ações</p>
        <RuleActions
          classes={classes}
          devices={devices}
          selectedActions={selectedActions}
          setSelectedActions={setSelectedActions}
        />

        <Button
          sx={{ my: '1.5em' }}
          variant="contained"
          color="primary"
          onClick={createRule}
        >
          Criar regra
        </Button>
      </Card>
    </div>
  )
}

export default withSnackbar(NewRule)
