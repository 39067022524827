import React from 'react'
import { ThemeProvider } from '@mui/material'

import { AuthProvider } from './contexts/auth'
import { SocketProvider } from './contexts/socket'
import { lightTheme } from './themes'
import Router from './Router'

function App() {
  return (
    <AuthProvider>
      <SocketProvider>
        <ThemeProvider theme={lightTheme}>
          <Router />
        </ThemeProvider>
      </SocketProvider>
    </AuthProvider>
  )
}

export default App
