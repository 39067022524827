import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { DeviceProvider } from '../contexts/device'
import EventProvider from '../contexts/event'
import Home from '../components/Home'
import Devices from '../pages/Devices'
import Device from '../pages/Device'
import DeviceForm from '../components/DeviceForm'
import Events from '../pages/Events'
import Alarms from '../pages/Alarms'
import Rules from '../pages/Rules'
import NewRule from '../pages/Rules/NewRule'
import LostRoute from './LostRoute'

const PrivateRoutes = () => {
  return (
    <DeviceProvider>
      <EventProvider>
        <Home>
          <Switch>
            <Route exact path="/devices" component={Devices} />
            <Route exact path="/devices/pending/:id" component={DeviceForm} />
            <Route exact path="/devices/:id" component={Device} />
            <Route exact path="/devices/:id/config" component={DeviceForm} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/alarms" component={Alarms} />
            <Route exact path="/rules" component={Rules} />
            <Route exact path="/rules/new" component={NewRule} />
            <Route render={(props) => <LostRoute {...props} to="/devices" />} />
          </Switch>
        </Home>
      </EventProvider>
    </DeviceProvider>
  )
}

export default PrivateRoutes
