import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Paper,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  InputBase,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const useStyles = makeStyles((theme) => ({
  filterBar: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    marginBottom: 30,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const FilterBar = ({
  onlineState,
  offlineState,
  sharedFilter,
  searchState,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState()
  const [online, setOnline] = onlineState
  const [offline, setOffline] = offlineState
  const [shared, setShared] = sharedFilter
  const [search, setSearch] = searchState

  return (
    <Paper className={classes.filterBar}>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Filtrar
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {}}>
          <FormControlLabel
            control={
              <Checkbox checked={online} onChange={() => setOnline(!online)} />
            }
            label="Online"
          />
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={offline}
                onChange={() => setOffline(!offline)}
              />
            }
            label="Offline"
          />
        </MenuItem>
        <MenuItem onClick={() => {}}>
          <FormControlLabel
            control={
              <Checkbox checked={shared} onChange={() => setShared(!shared)} />
            }
            label="Compartilhado"
          />
        </MenuItem>
      </Menu>
      <Divider className={classes.divider} orientation="vertical" />
      <InputBase
        className={classes.input}
        fullWidth
        placeholder="Pesquisar"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <SearchIcon />
    </Paper>
  )
}

export default FilterBar
