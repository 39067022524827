import { AlarmOff } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'

import { NoData } from '../../components/NoData'
import { useEvent } from '../../contexts/event'
import AlarmCard from './AlarmCard'

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: '0.5em',
  },
}))

const Alarms = () => {
  const classes = useStyles()
  const { activeAlarms, deleteNotifications } = useEvent()

  useEffect(() => {
    deleteNotifications()
  }, [deleteNotifications])

  return (
    <>
      <p className={classes.pageTitle}>Alarmes ativos</p>
      {activeAlarms && activeAlarms.length > 0 ? (
        <Grid container spacing={1}>
          {activeAlarms.map((alarm) => (
            <Grid item key={alarm.id} sm={4} xs={12}>
              <AlarmCard
                id={alarm.eventId}
                description={alarm.description}
                datetime={alarm.datetimeCreated}
                wifiSignalLevel={alarm.wifiSignalLevel}
                batteryLevel={alarm.batteryLevel}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoData Icon={AlarmOff} subject="alarme" />
      )}
    </>
  )
}

export default Alarms
