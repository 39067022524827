import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  MenuItem,
  Select,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ShowChart } from '@mui/icons-material'
import GetAppIcon from '@mui/icons-material/GetApp'
import EventNoteIcon from '@mui/icons-material/EventNote'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/pt-br'

import { requestApi } from '../../../helpers'
import { NoData } from '../../../components/NoData'
import { useSocket } from '../../../contexts/socket'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const processData = (data, sensorType) => {
  switch (sensorType) {
    case 'number': {
      return data.map((element) => ({
        value: Number(element.value),
        time: moment(element.time).valueOf(),
      }))
    }
    case 'on/off': {
      return data.map((element) => ({
        value: element.value === 'true' ? 1 : 0,
        time: moment(element.time).valueOf(),
      }))
    }
    case 'gps': {
      return data.map((element) => {
        return {
          time: moment(element.time).valueOf(),
          value: JSON.parse(element.value),
        }
      })
    }
    case 'heatmap': {
      return data.map((element) => {
        const data = { time: moment(element.time).valueOf() }

        for (const [index, value] of element.value.split(',').entries()) {
          data[`value${index}`] = Number(value)
        }

        return data
      })
    }
    default: {
      return {}
    }
  }
}

const fetchHistory = async (
  device,
  sensorId,
  startDate,
  endDate,
  download = false,
  qty = undefined
) => {
  let url = `device/history/${device.id}`

  url += `?startDate=${startDate.format()}`
  url += `&endDate=${endDate.format()}`
  url += `&sensorsKeys[]=${sensorId}`
  if (qty) url += `&qty=${qty}`

  try {
    if (download) {
      url += `&download=true`

      const response = await requestApi.get(url, {
        responseType: 'blob',
      })
      saveAs(response.data, `${device.id}.zip`)
      return
    }

    const response = await requestApi.get(url)
    const sensor = device.sensors[sensorId]

    const data = processData(response.data[sensorId], sensor.type)

    sensor.id = sensorId

    return {
      device,
      sensor,
      data,
    }
  } catch (error) {
    console.error(error)
  }
}

const DeviceHistory = ({ device }) => {
  const classes = useStyles()
  const { socket } = useSocket()

  const [history, setHistory] = useState({})
  const [startDate, setStartDate] = useState(moment().startOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [unsavedStartDate, setUnsavedStartDate] = useState(startDate)
  const [unsavedEndDate, setUnsavedEndDate] = useState(endDate)
  const [openDateRange, setOpenDateRange] = useState(false)
  const [mode, setMode] = useState(0)
  const [selectedSensor, setSelectedSensor] = useState(0)

  const sensors = Object.entries(device.sensors)
  const sensorType =
    device.sensors[selectedSensor] && device.sensors[selectedSensor].type

  const saveDate = () => {
    setStartDate(unsavedStartDate)
    setEndDate(unsavedEndDate)
  }

  const getHistory = useCallback(
    async (download = false) => {
      switch (mode) {
        case 'period': {
          return fetchHistory(
            device,
            selectedSensor,
            startDate,
            endDate,
            download
          )
        }
        case 'realtime': {
          return fetchHistory(
            device,
            selectedSensor,
            moment('19700101', 'YYYYMMDD'),
            moment(),
            download,
            30
          )
        }
        default: {
          return {
            device: {},
            sensor: {},
            data: [],
          }
        }
      }
    },
    [device, endDate, mode, selectedSensor, startDate]
  )

  useEffect(() => {
    ;(async () => {
      if (!device || !selectedSensor || !mode) return

      const history = await getHistory()
      setHistory(history)
    })()
  }, [device, mode, selectedSensor, getHistory])

  useEffect(() => {
    if (mode !== 'realtime' || !selectedSensor) return

    const updateSensorHistory = (measurements) => {
      setHistory((history) => {
        if (selectedSensor === measurements.sensorId) {
          history.data.pop()
          history.data.unshift(
            processData(
              [
                {
                  value: String(measurements.value),
                  time: Date.now(),
                },
              ],
              sensorType
            )[0]
          )
        }

        return { ...history }
      })
    }

    if (socket) socket.on('updateSensor', updateSensorHistory)

    return () => {
      socket.off('updateSensor', updateSensorHistory)
    }
  }, [socket, mode, selectedSensor, sensorType])

  const displayLines = () => {
    if (history.sensor.type === 'heatmap' && history.data[0]) {
      return Object.keys(history.data[0]).map(
        (key, index) =>
          key === 'time' || (
            <Line
              key={key}
              name={`${history.sensor.name}-${key.slice(-1)}`}
              type="monotone"
              dataKey={key}
              stroke={COLORS[index % COLORS.length]}
            />
          )
      )
    }

    return (
      <Line
        name={history.sensor.name}
        type="monotone"
        dataKey="value"
        stroke="#008585"
      />
    )
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              size="small"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              sx={{ color: '#008585', marginRight: 1 }}
            >
              <MenuItem disabled value={0}>
                SELECIONAR MODO
              </MenuItem>
              <MenuItem value="realtime">Tempo real</MenuItem>
              <MenuItem value="period">Período de tempo</MenuItem>
            </Select>
            {mode === 'period' && (
              <Button
                variant="outlined"
                startIcon={<EventNoteIcon color="primary" />}
                onClick={() => setOpenDateRange(true)}
              >
                Selecionar período
              </Button>
            )}
            <Select
              size="small"
              value={selectedSensor}
              onChange={(e) => setSelectedSensor(e.target.value)}
              sx={{ color: '#008585' }}
            >
              <MenuItem disabled value={0}>
                SELECIONAR SENSOR
              </MenuItem>
              {sensors
                .filter(([_, sensor]) => sensor.type !== 'gps')
                .map(([sensorId, sensor]) => (
                  <MenuItem key={sensorId} value={sensorId}>
                    {sensor.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        }
        action={
          <IconButton
            onClick={() => getHistory(true)}
            disabled={!selectedSensor || !mode}
          >
            <GetAppIcon />
          </IconButton>
        }
      />
      <CardContent style={{ height: 300 }}>
        {!history.data ? (
          <NoData
            Icon={ShowChart}
            subject="dado"
            subtitle="Selecione um sensor e um período para começar."
          />
        ) : !history.data.length ? (
          <NoData
            Icon={ShowChart}
            subject="dado para o período selecionado"
            subtitle=" "
          />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={history.data}>
              <CartesianGrid />
              <XAxis
                dataKey="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
                type="number"
                unit={history.sensor.unit}
                padding={{ left: 30 }}
              />
              <YAxis domain={['dataMin', 'dataMax']} padding={{ bottom: 30 }} />
              <Tooltip
                labelFormatter={(value) =>
                  moment(value).format('DD/MM/YYYY HH:mm:ss:SS')
                }
                formatter={(value) => [value, 'Valor']}
              />
              <Legend />
              {displayLines()}
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
      <Dialog open={openDateRange} onClose={() => setOpenDateRange(false)}>
        <DialogTitle>Escolha um período de tempo</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <MobileDateTimePicker
              label="Data inicial"
              value={unsavedStartDate}
              onChange={(datetime) => setUnsavedStartDate(datetime)}
            />
            <MobileDateTimePicker
              label="Data final"
              value={unsavedEndDate}
              minDateTime={unsavedStartDate}
              onChange={(datetime) => setUnsavedEndDate(datetime)}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenDateRange(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveDate()
              setOpenDateRange(false)
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default DeviceHistory
