import React from 'react'

import ChangePassword from '../../components/ChangePassword'
import { requestApi } from '../../helpers'

const RecoveryPassword = ({ location }) => {
  const handleSubmit = async (password) => {
    const query = new URLSearchParams(location.search)

    const email = query.get('email')
    const token = query.get('token')

    return await requestApi.post(`auth/user/forgot/confirm`, {
      email,
      token,
      newPassword: password,
    })
  }

  return <ChangePassword handleSubmit={handleSubmit} />
}

export default RecoveryPassword
