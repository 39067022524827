import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'

import { useSocket } from './socket'
import { requestApi } from '../helpers'
import withSnackbar from '../components/Snackbar'

const EventContext = createContext({})

const EventProvider = ({ children, snackbarShowMessage }) => {
  const { socket } = useSocket()

  const [events, setEvents] = useState([])
  const [eventsMeta, setEventsMeta] = useState({})
  const [activeAlarms, setActiveAlarms] = useState([])
  const [notifications, setNotifications] = useState([])

  const getEvents = async (page = 0) => {
    try {
      const response = await requestApi.get('/event', {
        params: {
          page: page + 1,
        },
      })
      setEvents(response.data.items)
      setEventsMeta(response.data.meta)
    } catch (err) {
      return console.log(err.message)
    }
  }

  const getActiveAlarms = async () => {
    try {
      const response = await requestApi.get('/event/alarm')
      setActiveAlarms(response.data)
    } catch (err) {
      return console.log(err.message)
    }
  }

  const getNotifications = async () => {
    try {
      const response = await requestApi.get('/event/notification')
      setNotifications(response.data)
    } catch (err) {
      return console.log(err.message)
    }
  }

  const deleteNotifications = useCallback(async () => {
    try {
      await requestApi.delete('/event/notification')
      setNotifications([])
    } catch (err) {
      return console.log(err.message)
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('newEvent', (newEvent) => {
        setEventsMeta((eventsMeta) => {
          const updatedTotal = { total: eventsMeta.total + 1 }
          return {
            ...eventsMeta,
            ...updatedTotal,
          }
        })
        setEvents((events) => {
          if (events.length > 10) {
            events.pop()
          }
          events.unshift(newEvent)
          return [...events]
        })
      })

      socket.on('alarmActivated', (newAlarm) => {
        setActiveAlarms((alarms) => [...alarms, newAlarm])
      })

      socket.on('alarmDeactivated', (deactivatedAlarm) => {
        setActiveAlarms((alarms) =>
          alarms.filter((alarm) => alarm.eventId !== deactivatedAlarm.eventId)
        )
      })

      socket.on('newNotification', (newNotification) => {
        setNotifications((notifications) => [...notifications, newNotification])
        snackbarShowMessage(
          `Nova notificação para o evento de id ${newNotification.eventId}`,
          'warning',
          6000
        )
      })
    }

    getNotifications()
    getEvents()
    getActiveAlarms()
  }, [socket, snackbarShowMessage])

  const useEventValues = useMemo(
    () => ({
      events,
      eventsMeta,
      getEvents,
      setEvents,
      activeAlarms,
      notifications,
      deleteNotifications,
    }),
    [activeAlarms, deleteNotifications, events, eventsMeta, notifications]
  )

  return (
    <EventContext.Provider value={useEventValues}>
      {children}
    </EventContext.Provider>
  )
}

export default withSnackbar(EventProvider)

export function useEvent() {
  const context = useContext(EventContext)
  return context
}
