import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import {
  AccountCircle,
  Cancel,
  Delete,
  Download,
  Link,
  MoreVert,
  Settings,
  Share,
} from '@mui/icons-material'
import moment from 'moment/moment'
import { saveAs } from 'file-saver'
import address from '@hapi/address'

import { requestApi } from '../../../helpers'
import withSnackbar from '../../../components/Snackbar'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const DeviceHeader = ({ device, deleteDevice, snackbarShowMessage }) => {
  const history = useHistory()

  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip title={device.online ? 'online' : 'offline'}>
            <span
              style={{
                height: 15,
                width: 15,
                backgroundColor: device.online ? green[400] : red[400],
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '0.5em',
              }}
            />
          </Tooltip>
          {device.shared && (
            <Tooltip title="compartilhado">
              <Link color="primary" style={{ marginRight: '0.25em' }} />
            </Tooltip>
          )}
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 24,
              marginRight: '0.25em',
            }}
          >
            {device.name}
          </Typography>{' '}
          <Chip label={device.class} size="small" color="primary" />
        </div>
        <Stack direction="row" justifyContent="end">
          <Tooltip
            title={
              device.shared && device.permissions !== 'manage'
                ? 'Você não possui as permissões necessárias'
                : 'Configurações'
            }
          >
            <span>
              <IconButton
                onClick={() => history.push(`/devices/${device.id}/config`)}
                disabled={device.shared && device.permissions !== 'manage'}
              >
                <Settings />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              device.shared
                ? 'Você não pode compartilhar um dispositivo que não é seu'
                : 'Compartilhar'
            }
          >
            <span>
              <IconButton
                onClick={() => setShareDialogOpen(true)}
                disabled={device.shared}
              >
                <Share />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null)
                setDownloadDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <Download fontSize="small" />
              </ListItemIcon>
              <ListItemText>Baixar CSV</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null)
                setDeleteDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Deletar</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
      </div>
      <Typography sx={{ marginBottom: '1em' }} color="gray">
        {device.description}
      </Typography>
      <ShareDialog
        device={device}
        shareDialogOpen={shareDialogOpen}
        setShareDialogOpen={setShareDialogOpen}
        snackbarShowMessage={snackbarShowMessage}
      />
      <DownloadDialog
        device={device}
        downloadDialogOpen={downloadDialogOpen}
        setDownloadDialogOpen={setDownloadDialogOpen}
      />
      <DeleteDialog
        device={device}
        deleteDevice={deleteDevice}
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
      />
    </>
  )
}

const ShareDialog = ({
  device,
  shareDialogOpen,
  setShareDialogOpen,
  snackbarShowMessage,
}) => {
  const [sharedEmail, setSharedEmail] = useState('')
  const [sharePermission, setSharePermission] = useState('view')
  const [sharedWithUsers, setSharedWithUsers] = useState([])

  const isSharedEmailValid = address.email.isValid(sharedEmail)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await requestApi.get(`/device/share/${device.id}`)
        setSharedWithUsers(data)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [device.id, shareDialogOpen])

  const shareDevice = async () => {
    if (sharedWithUsers.some((user) => user.email === sharedEmail)) {
      snackbarShowMessage(
        'Dispositivo já compartilhado com este usuário.',
        'error'
      )
      return
    }

    try {
      await requestApi.post(`/device/share/${device.id}`, {
        email: sharedEmail,
        permissions: sharePermission,
      })
      snackbarShowMessage('Dispositivo compartilhado com sucesso', 'success')
    } catch (e) {
      snackbarShowMessage(
        e.response.data.message ??
          'Ocorreu um erro ao compartilhar o dispositivo',
        'error'
      )
    }
  }

  const removeSharedUser = async (email) => {
    try {
      await requestApi.delete(`/device/share/${device.id}`, {
        params: {
          email,
        },
      })
      setSharedWithUsers(sharedWithUsers.filter((user) => user.email !== email))
      snackbarShowMessage('Usuário removido com sucesso', 'success')
    } catch (e) {
      snackbarShowMessage(
        'Ocorreu um erro ao tentar remover o usuário',
        'error'
      )
    }
  }

  return (
    <Dialog
      onClose={() => {
        setShareDialogOpen(false)
        setSharedEmail('')
      }}
      open={shareDialogOpen}
    >
      <DialogTitle>
        Com quem você deseja compartilhar o dispositivo?
      </DialogTitle>
      <DialogContent>
        <TextField
          value={sharedEmail}
          label="Email"
          variant="outlined"
          fullWidth
          onChange={(e) => setSharedEmail(e.target.value)}
        />
        <Stack>
          <Typography mb={1} fontWeight="bold">
            Permissões
          </Typography>
          <Select
            value={sharePermission}
            onChange={(e) => setSharePermission(e.target.value)}
          >
            <MenuItem value={'view'}>Visualizar</MenuItem>
            <MenuItem value={'manage'}>Visualizar e gerenciar</MenuItem>
          </Select>
        </Stack>
        <Stack>
          <Typography mt={3} mb={1} fontWeight="bold">
            Usuários com acesso
          </Typography>
          {sharedWithUsers.length ? (
            <List dense>
              {sharedWithUsers.map((user) => (
                <ListItem key={user.email}>
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.email}
                    secondary={
                      user.permissions === 'view'
                        ? 'Visualizar'
                        : 'Visualizar e gerenciar'
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title="Remover"
                      sx={{ ml: 2 }}
                      onClick={() => removeSharedUser(user.email)}
                    >
                      <IconButton color="error">
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="gray">
              Apenas você possui acesso a este dispositivo
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => {
            setShareDialogOpen(false)
            await shareDevice()
          }}
          disabled={!isSharedEmailValid}
        >
          Compartilhar
        </Button>
        <Button
          color="inherit"
          variant="contained"
          onClick={() => setShareDialogOpen(false)}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DownloadDialog = ({
  device,
  downloadDialogOpen,
  setDownloadDialogOpen,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [maxData, setMaxData] = useState('')

  const download = async () => {
    let url = `${process.env.REACT_APP_API_URL}/device/history/${device.id}?download=true`
    if (startDate && endDate) {
      let start = moment(startDate.toISOString()).format()
      let end = moment(endDate.toISOString()).format()
      url += `&startDate=${start}&endDate=${end}`
    }
    if (maxData.length) {
      url += `&qty=${maxData}`
    }
    try {
      let response = await requestApi.get(url, { responseType: 'blob' })
      saveAs(response.data, `${device.id}.zip`)
    } catch (error) {
      console.error(error)
    }

    setDownloadDialogOpen(false)
  }

  return (
    <Dialog
      onClose={() => setDownloadDialogOpen(false)}
      open={downloadDialogOpen}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label="Quantidade maxima"
          value={maxData}
          type="number"
          onChange={(event) => setMaxData(event.target.value)}
          inputProps={{
            min: 1,
          }}
        />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <MobileDateTimePicker
            label="Data inicial"
            value={startDate}
            onChange={(datetime) => setStartDate(datetime)}
          />
          <MobileDateTimePicker
            label="Data final"
            value={endDate}
            minDateTime={startDate}
            onChange={(datetime) => setEndDate(datetime)}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={download}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DeleteDialog = ({
  device,
  deleteDevice,
  deleteDialogOpen,
  setDeleteDialogOpen,
}) => {
  return (
    <Dialog onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen}>
      <DialogTitle>
        {device.shared
          ? 'Tem certeza que deseja remover o compartilhamento desse dispositivo?'
          : 'Tem certeza que deseja apagar esse dispositivo?'}
      </DialogTitle>
      {!device.shared && (
        <DialogContent>
          Todos os dados desse dispositivo serão apagados com ele e não será
          possivel reverter essa ação
        </DialogContent>
      )}
      <DialogActions>
        <Button
          color="error"
          variant="contained"
          onClick={async () => {
            setDeleteDialogOpen(false)
            await deleteDevice()
          }}
        >
          {device.shared ? 'Remover' : 'Apagar'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setDeleteDialogOpen(false)
          }}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(DeviceHeader)
