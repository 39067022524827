import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import { requestApi } from '../../helpers'
import Transition from '../../components/Transition'

const Confirm = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogContent, setDialogContent] = useState('')

  const history = useHistory()

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const email = query.get('email')
    const token = query.get('token')

    requestApi
      .post(`auth/user/confirm`, {
        email,
        token,
      })
      .then(() => {
        setDialogTitle('Confimação bem sucedida')
        setDialogContent(
          'Seu email foi confirmado com sucesso, agora você pode desfrutar do SAIOT'
        )
        setOpen(true)
      })
      .catch(({ response }) => {
        setDialogTitle('Erro')
        setDialogContent(response.data.message)
        setOpen(true)
      })
  }, [location.search])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => history.replace('/login'), 250)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Confirm
