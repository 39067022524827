import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material'
import { EventBusy } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'

import { useEvent } from '../../contexts/event'
import { NoData } from '../../components/NoData'

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: '0.5em',
  },
  tableContainer: {
    minHeight: '36.75em',
  },
}))

const Events = () => {
  const classes = useStyles()
  const { events, eventsMeta, getEvents } = useEvent()
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (!events) return

    setRows(
      events.map((event) => ({
        id: event.id,
        eventId: event.eventId,
        description: event.description,
        criticality: event.criticality,
        datetimeCreated: moment(event.datetimeCreated).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        wifiSignalLevel: event.wifiSignalLevel,
        batteryLevel: event.batteryLevel,
      }))
    )
  }, [events])

  const setNewPage = (_, page) => {
    setPage(page)
    getEvents(page)
  }

  return (
    <>
      <p className={classes.pageTitle}>Eventos</p>
      {events && events.length > 0 ? (
        <div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Criticidade</TableCell>
                  <TableCell>Data/Hora</TableCell>
                  <TableCell>Nível de WiFi</TableCell>
                  <TableCell>Nível de bateria</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.eventId}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.criticality}</TableCell>
                    <TableCell>{row.datetimeCreated}</TableCell>
                    <TableCell>{row.wifiSignalLevel}</TableCell>
                    <TableCell>{row.batteryLevel}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={eventsMeta.total || 0}
            page={page}
            onPageChange={setNewPage}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
            }
          />
        </div>
      ) : (
        <NoData Icon={EventBusy} subject="evento" />
      )}
    </>
  )
}

export default Events
