import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import ChangePassword from '../../components/ChangePassword'
import { requestApi } from '../../helpers'
import Transition from '../../components/Transition'

const Oauth = ({ location }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const history = useHistory()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    let status = Number(query.get('status'))
    if (status) {
      if (Number(query.get('status')) >= 300) {
        setOpen(true)
        setMessage(query.get('message'))
      }
    } else {
      history.replace('/login')
    }
  }, [location, history])

  const handleSubmit = async (password) => {
    const query = new URLSearchParams(location.search)

    const email = query.get('email')
    const token = query.get('token')
    const type = query.get('type')

    return await requestApi.post(`auth/user/${type}/confirm`, {
      email,
      token,
      password,
    })
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => history.replace('/login'), 250)
  }

  return (
    <>
      <ChangePassword OAuth handleSubmit={handleSubmit} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Oauth
