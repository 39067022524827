import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import {
  TextField,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Link } from 'react-router-dom'

import Transition from '../../components/Transition'
import styles from '../styles'
import address from '@hapi/address'
import { requestApi } from '../../helpers'

const useStyles = makeStyles((theme) => styles(theme))

const Register = () => {
  const classes = useStyles()

  const [fullName, setFullName] = useState(null)
  const [fullNameError, setFullNameError] = useState('')
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [confirmEmail, setConfirmEmail] = useState(null)
  const [confirmEmailError, setConfirmEmailError] = useState('')
  const [password, setPassword] = useState(null)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogContent, setDialogContent] = useState('')

  useEffect(() => {
    if (fullName === '') setFullNameError('Esse campo é obrigatorio')
    else setFullNameError('')
  }, [fullName])

  useEffect(() => {
    if (email === '') setEmailError('Esse campo é obrigatorio')
    else setEmailError('')
  }, [email])

  useEffect(() => {
    if (confirmEmail === '') setConfirmEmailError('Esse campo é obrigatorio')
    else setConfirmEmailError('')
  }, [confirmEmail])

  useEffect(() => {
    if (email !== confirmEmail) setConfirmEmailError('Os emails não são iguais')
    else setConfirmEmailError('')
  }, [email, confirmEmail])

  useEffect(() => {
    if (password === '') setPasswordError('Esse campo é obrigatorio')
    else setPasswordError('')
  }, [password])

  useEffect(() => {
    if (confirmPassword === '')
      setConfirmPasswordError('Esse campo é obrigatorio')
    else setConfirmPasswordError('')
  }, [confirmPassword])

  useEffect(() => {
    if (password !== confirmPassword)
      setConfirmPasswordError('As senhas não são iguais')
    else setConfirmPasswordError('')
  }, [password, confirmPassword])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validate()) {
      requestApi
        .post('/auth/user/register', {
          fullName,
          email,
          password,
        })
        .then(({ status, data }) => {
          if (status === 202) {
            setDialogTitle('Cadatro bem sucedido')
            setDialogContent(
              'Seu pedido de cadastro foi registrado, verifique seu email para fazer a confimação.'
            )
            setDialogOpen(true)
          }
          return data
        })
        .catch(({ response }) => {
          if (response.status === 409) {
            if (response.data.message === 'Email já cadastrado!') {
              setDialogTitle('Cadatro mal sucedido')
              setDialogContent(`O email '${email}' já está cadastrado.`)
              setDialogOpen(true)
            } else if (
              response.data.message ===
              'Um usuário já fez um pedido para cadastro desse email!'
            ) {
              setDialogTitle('Cadatro mal sucedido')
              setDialogContent(
                `O email '${email}' já tem um pedido de cadastro registrado, porém ainda não obtivemos confimação, por favor confira seu email.`
              )
              setDialogOpen(true)
            } else if (
              response.data.message === 'Falha interna ao criptografar senha' ||
              response.data.message === 'Falha interna no banco de dados'
            ) {
              setDialogTitle('Falha no sistema')
              setDialogContent(
                'Ocorreu m erro interno, tente mais tarde. Caso o erro persista entre em contato pelo saiot@ect.ufrn.br'
              )
              setDialogOpen(true)
            }
          }
        })
    }
  }

  const validate = () => {
    let bool = true
    if (!fullName) {
      setFullNameError('Esse campo é obrigatório')
      bool = false
    }
    if (!email) {
      setEmailError('Esse campo é obrigatório')
      bool = false
    } else if (!address.email.isValid(email)) {
      setEmailError('Email inválido')
      bool = false
    }
    if (!confirmEmail) {
      setConfirmEmailError('Esse campo é obrigatório')
      bool = false
    }
    if (!password) {
      setPasswordError('Esse campo é obrigatório')
      bool = false
    } else if (password.length < 8 || password.length > 32) {
      setPasswordError('A senha tem que ter entre 8 e 32 caracteres')
      bool = false
    }
    if (!confirmPassword) {
      setConfirmPasswordError('Esse campo é obrigatório')
      bool = false
    }

    if (
      fullNameError.length ||
      emailError.length ||
      confirmEmailError.length ||
      passwordError.length ||
      confirmPasswordError.length
    )
      bool = false
    return bool
  }

  return (
    <Paper className={classes.authFormPaper} elevation={3} variant="outlined">
      <div className={classes.mainHolder}>
        <div className={classes.subHolder}>
          <PermIdentityIcon className={classes.authIcon} />
        </div>
        <div className={classes.subHolder}>
          <form className={classes.authForm} onSubmit={handleSubmit}>
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Nome completo"
              value={fullName || ''}
              autoComplete="name"
              onChange={(event) => setFullName(event.target.value)}
              error={!!fullNameError}
              helperText={fullNameError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Email"
              value={email || ''}
              autoComplete="email"
              onChange={(event) => setEmail(event.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Confirmação do email"
              value={confirmEmail || ''}
              autoComplete="email"
              onChange={(event) => setConfirmEmail(event.target.value)}
              error={!!confirmEmailError}
              helperText={confirmEmailError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Senha"
              type={visiblePassword ? 'text' : 'password'}
              value={password || ''}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    {visiblePassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(event) => setPassword(event.target.value)}
              error={!!passwordError}
              helperText={passwordError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              fullWidth
              label="Confirmação da senha"
              type={visibleConfirmPassword ? 'text' : 'password'}
              value={confirmPassword || ''}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    onClick={() =>
                      setVisibleConfirmPassword(!visibleConfirmPassword)
                    }
                  >
                    {visibleConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(event) => setConfirmPassword(event.target.value)}
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              style={{ marginTop: 15 }}
            >
              Cadastrar-se
            </Button>
            <Link
              to={'/login'}
              style={{
                textDecoration: 'none',
                color: '#006064',
                fontWeight: 500,
                marginTop: 10,
                fontSize: 13,
              }}
            >
              Já tem uma conta?
            </Link>
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Register
