import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import {
  TextField,
  Paper,
  Button,
  Divider,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Link } from 'react-router-dom'
import address from '@hapi/address'

import styles from '../styles'
import sigaaIcon from '../../assets/logo_sigaa.svg'
import googleIcon from '../../assets/logo_google.svg'
import { useAuth } from '../../contexts/auth'

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  googleButton: {
    backgroundColor: '#4285f4',
    margin: '10px 0px 10px 0px',
    fontWeight: 'bolder',
    '&:hover': {
      backgroundColor: '#356AC3',
    },
  },
  divider: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  dividerHr: {
    width: '45%',
  },
  dividerSpan: {
    width: '10%',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.30)',
  },
  forgot: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const DialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Login = () => {
  const classes = useStyles()

  const { login } = useAuth()

  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState(null)
  const [passwordError, setPasswordError] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogContent, setDialogContent] = useState('')

  useEffect(() => {
    if (email === '') setEmailError('Esse campo é obrigatorio')
    else setEmailError('')
  }, [email])

  useEffect(() => {
    if (password === '') setPasswordError('Esse campo é obrigatorio')
    else setPasswordError('')
  }, [password])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validate()) {
      login(email, password).catch((err) => {
        setDialogTitle('Erro')
        setDialogContent(err.response.data.message)
        setDialogOpen(true)
      })
    }
  }

  const validate = () => {
    let bool = true
    if (!email) {
      setEmailError('Esse campo é obrigatório')
      bool = false
    } else if (!address.email.isValid(email)) {
      setEmailError('Email inválido')
      bool = false
    }
    if (!password) {
      setPasswordError('Esse campo é obrigatório')
      bool = false
    } else if (password.length < 8 || password.length > 32) {
      setPasswordError('Senha inválida')
      bool = false
    }
    if (emailError || passwordError) bool = false
    return bool
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <Paper className={classes.authFormPaper} elevation={3}>
      <div className={classes.mainHolder}>
        <div className={classes.subHolder}>
          <ExitToAppIcon className={classes.authIcon} />
        </div>
        <div className={classes.subHolder}>
          <form className={classes.authForm} onSubmit={handleSubmit}>
            <Button
              variant="contained"
              color="secondary"
              style={{ background: '#25a2b4', marginTop: 10 }}
              fullWidth
              startIcon={
                <img
                  alt="Logo do sigaa"
                  style={{ height: 20, padding: '2px 0px' }}
                  src={sigaaIcon}
                />
              }
              href={`${process.env.REACT_APP_API_URL}/auth/user/sigaa`}
            >
              {''}
            </Button>
            <Button
              className={classes.googleButton}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={
                <img
                  alt="Logo do google"
                  style={{
                    height: 20,
                    padding: 2,
                  }}
                  src={googleIcon}
                />
              }
              href={`${process.env.REACT_APP_API_URL}/auth/user/google`}
            >
              GOOGLE
            </Button>
            <div className={classes.divider}>
              <Divider className={classes.dividerHr} />
              <span className={classes.dividerSpan}>ou</span>
              <Divider className={classes.dividerHr} />
            </div>
            <TextField
              className={classes.authFormTextfield}
              required
              label="Email"
              autoComplete="email"
              fullWidth
              value={email || ''}
              onChange={(event) => setEmail(event.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              className={classes.authFormTextfield}
              required
              label="Senha"
              type={visiblePassword ? 'text' : 'password'}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton
                    tabIndex={-1}
                    style={{ padding: 0 }}
                    onClick={() => setVisiblePassword(!visiblePassword)}
                  >
                    {visiblePassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              fullWidth
              value={password || ''}
              onChange={(event) => setPassword(event.target.value)}
              error={!!passwordError}
              helperText={passwordError}
            />
            <div className={classes.forgot}>
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    onChange={() => console.log('opa, eae')}
                    value="checkedB"
                    color="primary"
                  />
                }
                label={
                  <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                    Manter login
                  </Typography>
                }
              />
              <Link
                to="/forgot"
                style={{
                  textDecoration: 'none',
                  color: '#006064',
                  fontWeight: 500,
                  fontSize: 13,
                }}
              >
                Esqueceu a senha?
              </Link>
            </div>
            <Button
              style={{ marginTop: 15 }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Entrar
            </Button>
            <Link
              to="register"
              style={{
                textDecoration: 'none',
                color: '#006064',
                fontWeight: 500,
                marginTop: 10,
                fontSize: 13,
              }}
            >
              Ainda não tem uma conta?
            </Link>
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        TransitionComponent={DialogTransition}
        keepMounted
        onClose={handleDialogClose}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default Login
