import { useHistory } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import { Link } from '@mui/icons-material'

const DeviceCard = ({ device }) => {
  const history = useHistory()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Card
      onClick={() => history.push(`devices/${device.id}`)}
      sx={{
        mb: 2,
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <Tooltip title={device.online ? 'online' : 'offline'}>
              <span
                style={{
                  height: 15,
                  width: 15,
                  backgroundColor: device.online ? green[400] : red[400],
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '0.5em',
                }}
              />
            </Tooltip>
            {device.shared && (
              <Tooltip title="compartilhado">
                <Link color="primary" style={{ marginRight: '0.25em' }} />
              </Tooltip>
            )}
            <Typography sx={{ mr: 1 }}>{device.name}</Typography>
            <Chip label={device.class} size="small" color="primary" />
          </Stack>
        }
        subheader={
          <div style={{ width: isMobile ? '80vw' : '45vw' }}>
            <Typography noWrap>{device.description}</Typography>
          </div>
        }
      />
    </Card>
  )
}

export default DeviceCard
