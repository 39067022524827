import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Add, Delete, Help } from '@mui/icons-material'
import React, { useState } from 'react'

const RuleActions = ({
  classes,
  devices,
  selectedActions,
  setSelectedActions,
}) => {
  const [selectedDevices, setSelectedDevices] = useState([0])

  const selectAction = (event) => {
    setSelectedActions((selectedActions) => {
      const selectedActionIndex = Number(event.target.name)
      const newAction = {
        type: event.target.value,
        deviceId: '0',
        actuatorId: '0',
        actuatorType: '0',
        actuatorValue: '0',
      }

      return selectedActions.map((action, index) =>
        index === selectedActionIndex ? newAction : action
      )
    })
  }

  const selectDevice = (selectedDeviceId, selectedDeviceIndex) => {
    const selectedDevice = devices.find(
      (device) => device.id === selectedDeviceId
    )
    setSelectedDevices((currentDevices) =>
      currentDevices.map((currentDevice, currentDeviceIndex) =>
        currentDeviceIndex === selectedDeviceIndex
          ? selectedDevice
          : currentDevice
      )
    )
  }

  const selectActuator = (actuatorId, actuatorIndex) => {
    setSelectedActions((selectedActions) =>
      selectedActions.map((currentAction, currentActionIndex) =>
        currentActionIndex === actuatorIndex
          ? {
              ...currentAction,
              deviceId: selectedDevices[actuatorIndex].id,
              actuatorId: actuatorId,
              actuatorType:
                selectedDevices[actuatorIndex].actuators[actuatorId].type,
            }
          : currentAction
      )
    )
  }

  const setActuatorValue = (actuatorValue, actuatorIndex) => {
    setSelectedActions((selectedActions) =>
      selectedActions.map((currentAction, currentActionIndex) =>
        currentActionIndex === actuatorIndex
          ? {
              ...currentAction,
              actuatorValue: actuatorValue,
            }
          : currentAction
      )
    )
  }

  const addAction = () => {
    setSelectedActions((currentActions) => [...currentActions, 0])
    setSelectedDevices((currentDevices) => [...currentDevices, 0])
  }

  const deleteAction = (deletedActionIndex) => {
    setSelectedActions((currentActions) =>
      currentActions.filter((_, index) => index !== deletedActionIndex)
    )
    setSelectedDevices((currentDevices) =>
      currentDevices.filter((_, index) => index !== deletedActionIndex)
    )
  }

  return (
    <div>
      {selectedActions?.map((action, index) => {
        const lastActionIndex = selectedActions.length - 1
        const isLastSelectedAction = index === lastActionIndex
        const isPlaceholder = !selectedActions[lastActionIndex]

        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              name={String(index)}
              value={action.type ?? 0}
              onChange={selectAction}
            >
              <MenuItem value={0}>Selecione uma ação</MenuItem>
              <MenuItem value={'act'}>Atuar</MenuItem>
            </Select>

            {action.type === 'act' && (
              <>
                <Select
                  className={classes.successiveSelect}
                  value={
                    (selectedDevices[index] && selectedDevices[index].id) || 0
                  }
                  defaultValue={0}
                  onChange={(e) => selectDevice(e.target.value, index)}
                >
                  <MenuItem value={0} disabled>
                    Selecione um dispositivo
                  </MenuItem>
                  {devices.map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.name}
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  className={classes.successiveSelect}
                  value={action.actuatorId || 0}
                  onChange={(e) => selectActuator(e.target.value, index)}
                >
                  <MenuItem value={0} disabled>
                    Selecione um atuador
                  </MenuItem>
                  {selectedDevices[index] &&
                    selectedDevices[index].actuators &&
                    Object.entries(selectedDevices[index].actuators).map(
                      ([id]) => (
                        <MenuItem key={id} value={id}>
                          {id}
                        </MenuItem>
                      )
                    )}
                </Select>

                <TextField
                  className={classes.successiveSelect}
                  sx={{ ml: '2em' }}
                  id="actuator-value"
                  label="Valor"
                  value={action.actuatorValue}
                  onChange={(e) => setActuatorValue(e.target.value, index)}
                />

                <Tooltip
                  title={
                    <>
                      <Typography
                        style={{ fontWeight: 'bold' }}
                        color="inherit"
                      >
                        Formatos de valores aceitos
                      </Typography>
                      <Typography color="inherit">Color - R,G,B,A</Typography>
                      <Typography color="inherit">Number - Número</Typography>
                      <Typography color="inherit">Slider - Número</Typography>
                      <Typography color="inherit">
                        On/Off - 'true' ou 'false'
                      </Typography>
                    </>
                  }
                >
                  <Help fontSize="small" color="disabled" />
                </Tooltip>

                {isLastSelectedAction && !isPlaceholder && (
                  <IconButton
                    onClick={addAction}
                    style={{ marginLeft: '0.5em' }}
                    size="small"
                  >
                    <Add />
                  </IconButton>
                )}
                {!isLastSelectedAction && (
                  <IconButton
                    onClick={() => deleteAction(index)}
                    style={{ marginLeft: '0.5em' }}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        )
      })}
    </div>
  )
}

export default RuleActions
