import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  AppBar,
  Toolbar,
  IconButton,
  Chip,
  Menu,
  MenuItem,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'

import { useAuth } from '../../contexts/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
  },
  grow: {
    flexGrow: 1,
  },
}))

const TopBar = (props) => {
  const classes = useStyles()

  const { user, logout } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <AppBar
      color="primary"
      className={classes.appBar}
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => props.setOpenDrawer(!props.openDrawer)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.grow} />
        <div>
          <Chip
            icon={<PersonIcon style={{ color: '#e0e0e0' }} />}
            style={{
              backgroundColor: 'transparent',
              color: 'white',
              border: '1px solid rgba(255, 255, 255, 0.23)',
            }}
            label={user.fullName}
            clickable
            variant="outlined"
            onClick={(event) => setAnchorEl(event.target)}
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={logout}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
