import React, { useCallback } from 'react'
import { Builder, Query } from '@react-awesome-query-builder/mui'

const Rule = ({ ruleState, setRuleState }) => {
  const onChange = useCallback(
    (immutableTree, config) =>
      setRuleState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config: config,
      })),
    [setRuleState]
  )

  const renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  )

  return (
    <Query
      {...ruleState.config}
      value={ruleState.tree}
      onChange={onChange}
      renderBuilder={renderBuilder}
    />
  )
}

export default Rule
