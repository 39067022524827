import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import { useSocket } from './socket'
import { requestApi } from '../helpers'

const DeviceContext = createContext({})

export const DeviceProvider = (props) => {
  const { socket } = useSocket()

  const [devices, setDevices] = useState([])
  const [pendingDevices, setPendingDevices] = useState()

  useEffect(() => {
    const getDevices = async () => {
      try {
        const response = await requestApi.get('/device')
        setDevices(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    const getPendings = async () => {
      try {
        const response = await requestApi.get('/device/pending')

        if (Object.entries(response.data).length === 0) {
          setPendingDevices(null)
        } else {
          setPendingDevices(response.data)
        }
      } catch (err) {
        return console.log(err.message)
      }
    }

    const handleNewPendingDevice = (newPendingDevice) => {
      setPendingDevices((prevState) => ({
        ...prevState,
        ...newPendingDevice,
      }))
    }

    const handleDeviceConnected = (deviceID) => {
      setDevices((prevState) => {
        let device = prevState.find((device) => device.id === deviceID)
        if (device) {
          device.online = true
        }

        return [...prevState]
      })
    }

    const handleDeviceDisconnected = (deviceID) => {
      setDevices((prevState) => {
        let device = prevState.find((device) => device.id === deviceID)
        if (device) {
          device.online = false
        }

        return [...prevState]
      })
    }

    const handleSharedDeviceAdded = () => {
      getDevices()
    }

    const handleSharedDeviceDeleted = (deviceID) => {
      setDevices((prevState) =>
        prevState.filter((device) => device.id !== deviceID)
      )
    }

    if (socket) {
      socket.on('newPendingDevice', handleNewPendingDevice)
      socket.on('deviceConnected', handleDeviceConnected)
      socket.on('deviceDisconnected', handleDeviceDisconnected)
      socket.on('sharedDeviceAdded', handleSharedDeviceAdded)
      socket.on('sharedDeviceDeleted', handleSharedDeviceDeleted)
    }

    getDevices()
    getPendings()

    return () => {
      if (socket) {
        socket.off('newPendingDevice', handleNewPendingDevice)
        socket.off('deviceConnected', handleDeviceConnected)
        socket.off('deviceDisconnected', handleDeviceDisconnected)
        socket.off('sharedDeviceAdded', handleSharedDeviceAdded)
        socket.off('sharedDeviceDeleted', handleSharedDeviceDeleted)
      }
    }
  }, [socket])

  const addPendingDevice = useCallback(
    (id) => {
      setDevices((prevState) => {
        let device = pendingDevices[id]
        device.id = id
        prevState.push(device)

        return [...prevState]
      })
      removePendingDevice(id)
    },
    [pendingDevices]
  )

  function removePendingDevice(id) {
    setPendingDevices((prevState) => {
      delete prevState[id]

      if (Object.entries(prevState).length === 0) {
        return null
      }

      return { ...prevState }
    })
  }

  function updateDevaice(config) {
    setDevices((prevState) => {
      let index = prevState.findIndex((device) => device.id === config.id)
      prevState[index] = config

      return [...prevState]
    })
  }

  const useDeviceValues = useMemo(
    () => ({
      devices,
      setDevices,
      pendingDevices,
      setPendingDevices,
      addPendingDevice,
      removePendingDevice,
      updateDevaice,
    }),
    [devices, pendingDevices, addPendingDevice]
  )

  return (
    <DeviceContext.Provider value={useDeviceValues}>
      {props.children}
    </DeviceContext.Provider>
  )
}

export function useDevice() {
  const context = useContext(DeviceContext)
  return context
}
