import React from 'react'
import { makeStyles } from '@mui/styles'
import { Switch } from '@mui/material'

import { requestApi } from '../../../../helpers'

const useStyles = makeStyles(() => ({
  switchActuator: { margin: -9 },
}))

const SwitchActuator = ({ deviceId, id, value }) => {
  const classes = useStyles()

  const handleChange = async () => {
    try {
      await requestApi.post(`/device/${deviceId}/act`, {
        id,
        parameters: [!value],
      })
    } catch (error) {
      console.error('erro: ', error.response)
    }
  }

  return (
    <div className={classes.switchActuator}>
      <Switch color="primary" checked={value} onChange={handleChange} />
    </div>
  )
}

export default SwitchActuator
