import { MuiConfig } from '@react-awesome-query-builder/mui'

function getFields(devices) {
  const fields = {}

  for (const device of devices) {
    if (!device.sensors) return fields

    const sensors = Object.entries(device.sensors)

    for (const [sensorId, sensor] of sensors) {
      const fieldId = `${device.id}:${sensorId}`
      const fieldLabel = `${device.name}:${sensorId}`

      switch (sensor.type) {
        case 'on/off': {
          fields[fieldId] = {
            label: fieldLabel,
            type: 'boolean',
            operators: ['equal'],
            valueSources: ['value', 'field'],
          }
          break
        }
        case 'number': {
          fields[fieldId] = {
            label: fieldLabel,
            type: 'number',
            valueSources: ['value', 'field'],
            preferWidgets: ['number'],
          }
          break
        }
        case 'gps': {
          fields[fieldId] = {
            label: fieldLabel,
            type: '!group',
            mode: 'array',
            conjunctions: ['AND', 'OR'],
            operators: ['some', 'all', 'none'],
            showNot: true,
            subfields: {
              Latitude: {
                type: 'number',
                valueSources: ['value', 'field'],
                preferWidgets: ['number'],
              },
              Longitude: {
                type: 'number',
                valueSources: ['value', 'field'],
                preferWidgets: ['number'],
              },
            },
          }
          break
        }
        default:
          break
      }
    }
  }

  return fields
}

export function getConfig(devices) {
  const config = {
    ...MuiConfig,
    fields: {},
  }

  if (!devices || !devices[0]) return config

  config.fields = getFields(devices)

  return config
}
