import React from 'react'
import { Box, IconButton, MenuItem, Select } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'

const RuleDevices = ({ devices, selectedDevices, setSelectedDevices }) => {
  const selectDevice = (event) => {
    setSelectedDevices((currentDevices) => {
      const selectedDevice = devices.find(
        (device) => device.id === event.target.value
      )
      const selectedDeviceIndex = Number(event.target.name)
      const isAlreadySelected = currentDevices.some(
        (currentDevice) => event.target.value === currentDevice.id
      )

      if (selectedDevice && !isAlreadySelected) {
        return currentDevices.map((currentDevice, currentDeviceIndex) =>
          currentDeviceIndex === selectedDeviceIndex
            ? selectedDevice
            : currentDevice
        )
      }

      return currentDevices
    })
  }

  const addDevice = () => {
    setSelectedDevices((currentDevices) => [...currentDevices, 0])
  }

  const deleteDevice = (deletedDeviceIndex) => {
    setSelectedDevices((currentDevices) =>
      currentDevices.filter(
        (_, currentDeviceindex) => currentDeviceindex !== deletedDeviceIndex
      )
    )
  }

  return (
    <>
      {selectedDevices?.map((device, index) => {
        const lastSeletecDeviceIndex = selectedDevices.length - 1
        const isLastSelectedDevice = index === lastSeletecDeviceIndex
        const isPlaceholder = !selectedDevices[lastSeletecDeviceIndex]

        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Select
              name={String(index)}
              value={device.id ?? 0}
              defaultValue={0}
              onChange={selectDevice}
            >
              <MenuItem value={0} disabled>
                Selecione um dispositivo
              </MenuItem>
              {devices.map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.name}
                </MenuItem>
              ))}
            </Select>
            {isLastSelectedDevice && !isPlaceholder && (
              <IconButton
                onClick={addDevice}
                style={{ marginLeft: '0.5em' }}
                size="small"
              >
                <Add />
              </IconButton>
            )}
            {!isLastSelectedDevice && (
              <IconButton
                onClick={() => deleteDevice(index)}
                style={{ marginLeft: '0.5em' }}
                size="small"
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        )
      })}
    </>
  )
}

export default RuleDevices
