import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Sensors, TouchApp } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { useDevice } from '../../contexts/device'
import DeviceHistory from './components/History'
import { requestApi } from '../../helpers'
import ModulesGrid from './components/ModulesGrid'
import DeviceHeader from './components/DeviceHeader'
import { useAuth } from '../../contexts/auth'

const Device = () => {
  const { devices, setDevices } = useDevice()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const history = useHistory()
  const location = useLocation()
  const { user } = useAuth()

  const deviceId = location.pathname.split('/')[2]
  const device = devices.find((d) => d.id === deviceId)

  const deleteDevice = async () => {
    try {
      if (device.shared) {
        await requestApi.delete(`/device/share/${device.id}`, {
          params: {
            email: user.email,
          },
        })
      } else {
        await requestApi.delete(`/device/${device.id}`)
      }

      history.push('/devices')
      setDevices((devices) => devices.filter((d) => d.id !== device.id))
    } catch (e) {
      console.error(e)
    }
  }

  if (!device) return <></>

  return (
    <>
      <DeviceHeader device={device} deleteDevice={deleteDevice} />
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Stack spacing={2}>
          <StatsCard
            stat={Object.keys(device.sensors).length}
            title="Total de sensores"
            Icon={Sensors}
          />
          <StatsCard
            stat={Object.keys(device.actuators).length}
            title="Total de atuadores"
            Icon={TouchApp}
          />
        </Stack>
        <Box sx={{ flexGrow: 3, maxWidth: '100%' }}>
          <DeviceHistory device={device} />
        </Box>
      </Stack>
      <ModulesGrid device={device} />
    </>
  )
}

const StatsCard = ({ title, stat, Icon }) => (
  <Card>
    <CardContent>
      <Stack direction="row" alignItems="center">
        <Icon color="primary" />
        <Stack ml={2} mr>
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography>{stat}</Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
)

export default Device
