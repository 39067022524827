import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Auth from '../components/Auth'
import Login from '../pages/Login'
import Oauth from '../pages/Oauth'
import Register from '../pages/Register'
import Forgot from '../pages/Forgot'
import RecoveryPassword from '../pages/RecoveryPassword'
import Confirm from '../pages/Confirm'
import LostRoute from './LostRoute'

const PublicRoutes = () => {
  return (
    <Auth>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/oauth" component={Oauth} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/recovery-password" component={RecoveryPassword} />
        <Route exact path="/confirm" component={Confirm} />
        <Route render={(props) => <LostRoute {...props} to="/login" />} />
      </Switch>
    </Auth>
  )
}

export default PublicRoutes
