import React from 'react'
import { makeStyles } from '@mui/styles'

import nightCity from '../../assets/night-city.png'

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
  },
}))

const Auth = ({ children }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.background}
      style={{ backgroundImage: `url(${nightCity})` }}
    >
      {children}
    </div>
  )
}

export default Auth
