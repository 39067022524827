import React from 'react'
import { makeStyles } from '@mui/styles'
import { IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { requestApi } from '../../../../helpers'

const useStyles = makeStyles(() => ({
  numberActuator: { display: 'flex', alignItems: 'center', margin: -7 },
}))

const NumberActuator = ({ deviceId, id, value }) => {
  const classes = useStyles()

  const increment = async () => {
    try {
      await requestApi.post(`/device/${deviceId}/act`, {
        id,
        parameters: [value - 1],
      })
    } catch (error) {
      console.error('erro: ', error.response)
    }
  }

  const decrement = async () => {
    try {
      await requestApi.post(`/device/${deviceId}/act`, {
        id,
        parameters: [value + 1],
      })
    } catch (error) {
      console.error('erro: ', error.response)
    }
  }
  return (
    <div className={classes.numberActuator}>
      <IconButton style={{ padding: 0 }} onClick={increment}>
        <ChevronLeftIcon />
      </IconButton>
      <span>{value}</span>
      <IconButton style={{ padding: 0 }} onClick={decrement}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  )
}

export default NumberActuator
