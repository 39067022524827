import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import Chrome from '@uiw/react-color-chrome'
import { rgbaToHsva } from '@uiw/color-convert'

import useSmartSend from '../../../../hooks/useSmartSend'

const useStyles = makeStyles(() => ({
  colorActuator: { display: 'flex', alignItems: 'center', margin: -7 },
}))

const convertRgbaToHsva = (rgba) => {
  return rgbaToHsva({ r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] / 255 })
}

const ColorActuator = ({ deviceId, id, value }) => {
  const classes = useStyles()
  const [color, setColor] = useState(convertRgbaToHsva(value))
  const sendDeviceValue = useSmartSend(deviceId, id)

  const changeColor = ({ hsva, rgba }) => {
    const color = [rgba.r, rgba.g, rgba.b, Math.floor(rgba.a * 255)]

    setColor(hsva)
    sendDeviceValue(color)
  }

  useEffect(() => {
    setColor(convertRgbaToHsva(value))
  }, [value])

  return (
    <div className={classes.colorActuator}>
      <Chrome color={color} onChange={changeColor} />
    </div>
  )
}

export default ColorActuator
