import { Card, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AccessTime, BatteryFullOutlined, Wifi } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginLeft: '0.5em',
    marginRight: '0.25em',
  },
  firstIcon: {
    color: theme.palette.primary.main,
    marginRight: '0.25em',
  },
  detailsSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5em',
  },
  cardTitle: {
    fontWeight: 'bold',
  },
}))

const AlarmCard = ({
  id,
  description,
  datetime,
  wifiSignalLevel,
  batteryLevel,
}) => {
  const classes = useStyles()

  return (
    <Card>
      <CardContent>
        <Typography noWrap={true} className={classes.cardTitle}>
          {id}
        </Typography>
        {description && (
          <Typography noWrap={true} color="textSecondary">
            {description}
          </Typography>
        )}
        <div className={classes.detailsSection}>
          <Wifi className={classes.firstIcon} />
          <Typography>{wifiSignalLevel}</Typography>
          <BatteryFullOutlined className={classes.icon} />
          <Typography>{batteryLevel}</Typography>
          <AccessTime className={classes.icon} />
          <Typography>{datetime}</Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default AlarmCard
