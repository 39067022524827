import React, { useState } from 'react'
import { IconButton, Dialog } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close'
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip as LeafletToolTip,
} from 'react-leaflet'

const Map = ({ position }) => {
  const [fullscreen, setFullscreen] = useState(false)

  return (
    <>
      <MapContainer
        style={{ height: 200, width: 300 }}
        center={position}
        zoom={16}
        scrollWheelZoom={true}
        maxZoom={18}
      >
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: '1000',
            padding: 0,
            width: 35,
            height: 35,
            backgroundColor: 'white',
            boxShadow: '0 0 3px black',
          }}
          onClick={() => setFullscreen(true)}
        >
          <FullscreenIcon />
        </IconButton>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <LeafletToolTip direction="top">
            {`Latitude: ${position[0].toFixed(4)}`}
            <br />
            {`Longitude: ${position[1].toFixed(4)}`}
          </LeafletToolTip>
        </Marker>
      </MapContainer>

      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={fullscreen}
        onClose={() => setFullscreen(false)}
      >
        <MapContainer
          style={{ width: '100%', height: '90vh' }}
          center={position}
          zoom={16}
          scrollWheelZoom={true}
          maxZoom={18}
        >
          <IconButton
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              zIndex: '1000',
              padding: 0,
              width: 35,
              height: 35,
              backgroundColor: 'white',
              boxShadow: '0 0 3px black',
            }}
            onClick={() => setFullscreen(false)}
          >
            <CloseIcon />
          </IconButton>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <LeafletToolTip direction="top">
              {`Latitude: ${position[0].toFixed(4)}`}
              <br />
              {`Longitude: ${position[1].toFixed(4)}`}
            </LeafletToolTip>
          </Marker>
        </MapContainer>
      </Dialog>
    </>
  )
}

export default Map
