import { useEffect, useState } from 'react'
import {
  PanTool,
  PanToolOutlined,
  KeyboardArrowDown,
} from '@mui/icons-material'
import { Responsive, WidthProvider } from 'react-grid-layout'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import Map from '../../../components/Map'
import HeatMapSensor from './sensors/HeatMapSensor'
import SwitchActuator from './actuators/SwitchActuator'
import NumberActuator from './actuators/NumberActuator'
import ColorActuator from './actuators/ColorActuator'
import SliderActuator from './actuators/SliderActuator'
import { useSocket } from '../../../contexts/socket'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

const ModulesGrid = ({ device }) => {
  const { socket } = useSocket()

  const [isDraggable, setIsDraggable] = useState(false)
  const [anchorEl, setAnchorEl] = useState()
  const [sensorsFilter, setSensorsFilter] = useState(true)
  const [actuatorsFilter, setActuatorsFilter] = useState(true)

  const [sensorsValues, setSensorsValues] = useState(
    getModuleValues(device.sensors)
  )
  const [actuatorsValues, setActuatorsValues] = useState(
    getModuleValues(device.actuators)
  )

  const modules = Object.assign(
    {},
    sensorsFilter ? device.sensors : {},
    actuatorsFilter ? device.actuators : {}
  )

  const getLayout = (cols) =>
    Object.keys(modules).map((key, index) => ({
      i: key,
      x: (index * 3) % cols,
      y: 0,
      w: 3,
      h: 9,
    }))

  const breakpoints = { md: 996, xs: 600, xxs: 0 }
  const cols = { md: 12, xs: 6, xxs: 1 }
  const layouts = {
    md: getLayout(cols.md),
    xs: getLayout(cols.xs),
    xxs: getLayout(cols.xxs),
  }

  useEffect(() => {
    const handleUpdateSensor = (measurements) => {
      setSensorsValues((values) => ({
        ...values,
        [measurements.sensorId]: measurements.value,
      }))
    }

    const handleUpdateActuator = (act) => {
      setActuatorsValues((values) => ({
        ...values,
        [act.actuatorId]: act.value,
      }))
    }

    socket.on('updateSensor', handleUpdateSensor)
    socket.on('updateActuator', handleUpdateActuator)

    return () => {
      socket.off('updateSensor', handleUpdateSensor)
      socket.off('updateActuator', handleUpdateActuator)
    }
  }, [socket])

  return (
    <Stack mb={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography fontWeight="bold" fontSize={18}>
          Sensores e atuadores
        </Typography>
        <Stack direction="row" alignItems="center">
          <Tooltip title="Reorganizar">
            <Checkbox
              icon={<PanToolOutlined color="primary" />}
              checkedIcon={<PanTool />}
              checked={isDraggable}
              onChange={(e) => setIsDraggable(e.target.checked)}
            />
          </Tooltip>
          <Button
            endIcon={<KeyboardArrowDown />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Filtrar
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sensorsFilter}
                    onChange={() => setSensorsFilter(!sensorsFilter)}
                  />
                }
                label="Sensores"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={actuatorsFilter}
                    onChange={() => setActuatorsFilter(!actuatorsFilter)}
                  />
                }
                label="Atuadores"
              />
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>

      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        cols={cols}
        breakpoints={breakpoints}
        rowHeight={30}
        isResizable={false}
        isDraggable={isDraggable}
      >
        {Object.entries(modules).map(([id, m]) => (
          <Card key={id} sx={{ flexGrow: '1', padding: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="gray">{m.name}</Typography>
              <Chip
                color="primary"
                label={
                  Object.keys(device.sensors).includes(id)
                    ? 'sensor'
                    : 'atuador'
                }
                size="small"
              />
            </Stack>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {Object.keys(device.sensors).includes(id)
                ? getSensor(m, sensorsValues[id])
                : getActuator(m, id, actuatorsValues[id], device.id)}
            </CardContent>
          </Card>
        ))}
      </ResponsiveGridLayout>
    </Stack>
  )
}

const getModuleValues = (module) =>
  Object.entries(module).reduce(
    (obj, [id, s]) => ({ ...obj, [id]: s.value }),
    {}
  )

const getSensor = (sensor, value) => {
  switch (sensor.type) {
    case 'on/off': {
      const labelOn = sensor.labelOn ?? 'Ligado'
      const labelOff = sensor.labelOff ?? 'Desligado'

      return value ? labelOn : labelOff
    }
    case 'number': {
      let sensorValue = '?'

      if (value !== null && value !== undefined) {
        sensorValue = value

        if (!!sensor.max) {
          sensorValue += ` / ${sensor.max}`
        }

        if (!!sensor.unit) {
          sensorValue += ` ${sensor.unit}`
        }
      }

      return sensorValue
    }
    case 'gps': {
      return <Map position={value} />
    }
    case 'heatmap': {
      return <HeatMapSensor matrix={value} min={sensor.min} max={sensor.max} />
    }
    default:
      return ''
  }
}

const getActuator = (actuator, actuatorId, value, deviceId) => {
  switch (actuator.type) {
    case 'switch': {
      return (
        <SwitchActuator deviceId={deviceId} id={actuatorId} value={value} />
      )
    }
    case 'number': {
      return (
        <NumberActuator deviceId={deviceId} id={actuatorId} value={value} />
      )
    }
    case 'color': {
      return <ColorActuator deviceId={deviceId} id={actuatorId} value={value} />
    }
    case 'slider': {
      return (
        <SliderActuator
          deviceId={deviceId}
          id={actuatorId}
          min={actuator.min}
          max={actuator.max}
          step={actuator.step}
          value={value}
        />
      )
    }
    default:
      return <></>
  }
}

export default ModulesGrid
