import { Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'

import { useDevice } from '../../contexts/device'
import FilterBar from './components/FilterBar'
import PendingDevices from './components/PendingDevices'
import DeviceCard from './components/DeviceCard'

const Devices = () => {
  const { devices } = useDevice()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const onlineFilter = useState(true)
  const offlineFilter = useState(true)
  const sharedFilter = useState(true)
  const search = useState('')

  return (
    <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
      <Stack sx={{ flexGrow: 3 }}>
        <Typography fontWeight="bold" fontSize={24} mb={2}>
          Dispositivos
        </Typography>
        <FilterBar
          onlineState={onlineFilter}
          offlineState={offlineFilter}
          sharedFilter={sharedFilter}
          searchState={search}
        />
        {devices &&
          devices
            .filter(
              (device) =>
                (device.online && onlineFilter[0]) ||
                (!device.online && offlineFilter[0]) ||
                (device.shared && sharedFilter[0])
            )
            .filter((device) => device.name.toLowerCase().includes(search[0]))
            .map((device, index) => <DeviceCard key={index} device={device} />)}
      </Stack>
      <PendingDevices />
    </Stack>
  )
}

export default Devices
