import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import Transition from '../../components/Transition'
import { requestApi } from '../../helpers'

const Forgot = () => {
  const [openMain, setOpenMain] = useState(true)
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState('')
  const [openConfim, setOpenConfim] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogContent, setDialogContent] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (email === '') {
      setEmailError('Esse campo é obrigatório')
    } else {
      setEmailError('')
    }
  }, [email])

  const handleClose = (state) => {
    if (state === 'openMain') setOpenMain(false)
    else if (state === 'openConfim') setOpenConfim(false)
    setTimeout(() => history.replace('/login'), 250)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validate()) {
      requestApi
        .post('/auth/user/forgot', { email })
        .then(() => {
          setOpenMain(false)
          setDialogTitle('Email enviado')
          setDialogContent(
            'Seu email foi enviado, verifique sua caixa de entrada'
          )
          setOpenConfim(true)
        })
        .catch((err) => {
          setDialogTitle('Falha no envio do email')
          setDialogContent(
            'Não foi possivel enviar seu email, tente mais tarde. Caso o erro perssita envie um email para saiot@ect.ufrn.br'
          )
          setOpenConfim(true)
        })
    }
  }

  const validate = () => {
    let bool = true
    if (!email) {
      setEmailError('Esse campo é obrigatório')
      bool = false
    }
    if (!!emailError) bool = false
    return bool
  }

  return (
    <>
      <Dialog
        disableBackdropClick
        open={openMain}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('openMain')}
      >
        <DialogTitle>Esqueceu sua senha?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite seu email para podemor te enviar o link de mudança de senha.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              required
              autoFocus
              label="Email"
              type="email"
              fullWidth
              value={email || ''}
              onChange={(event) => setEmail(event.target.value)}
              error={!!emailError}
              helperText={emailError}
            />
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('openMain')} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        open={openConfim}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('openMain')}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('openConfim')} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Forgot
