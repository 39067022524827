import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'

import { lightTheme } from '../../themes'
import TopBar from './TopBar'
import Navegator from './Navegator'
import { useMediaQuery } from '@mui/material'
import { useEffect } from 'react'

const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
  },
  contentShift: {
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
  },
  main: {
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 56px)',
    },
  },
}))

const Home = (props) => {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const [openDrawer, setOpenDrawer] = useState(!isMobile)

  useEffect(() => setOpenDrawer(!isMobile), [isMobile])

  return (
    <div>
      <div
        className={classes.root}
        style={{ background: lightTheme.palette.background.main }}
      >
        <TopBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        <Navegator openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

        <div className={openDrawer ? classes.contentShift : classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.main}>{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default Home
