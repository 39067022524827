import { requestApi } from '../helpers'
import useThrottle from './useThrottle'

export default function useSmartSend(deviceId, actuatorId) {
  const delayBetweenRequests = 1000 / 10

  const sendRequest = async (value) => {
    try {
      await requestApi.post(`/device/${deviceId}/act`, {
        id: actuatorId,
        parameters: value,
      })
    } catch (error) {
      console.error('erro: ', error.response)
    }
  }

  return useThrottle(sendRequest, delayBetweenRequests)
}
