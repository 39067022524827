import axios from 'axios'
import qs from 'qs'

export const requestApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
})
